<template>
    <!-- this component has no element -->
</template>

<script>
import Player from "@vimeo/player";

export default{
    props:{
        millisecondsMouseMoveEvent:Number
    },
    data(){
        return {
            vimeoPlayer: null,
            fakeEventCallInterval: null,
        }
    },
    mounted(){
        setTimeout(() => {
            this.initializeVimeoPlayer();
        }, 500);
    },
    beforeDestroy() {
        this.destroyInterval();
    },
    methods:{
        initializeVimeoPlayer() {
            const iframe = document.getElementById("vimeoPlayer");
            this.vimeoPlayer = new Player(iframe);

            this.vimeoPlayer.on("play", () => {
                this.destroyInterval();
                this.fakeEventCallInterval = setInterval(() => {
                    window.dispatchEvent(new Event("mousemove"));
                }, this.millisecondsMouseMoveEvent);
            });

            this.vimeoPlayer.on("pause", () => {
                this.destroyInterval();
            });
        },
        destroyInterval(){
            if (this.fakeEventCallInterval) {
                clearInterval(this.fakeEventCallInterval);
                this.fakeEventCallInterval = null;
            }
        }
    }
};

</script>